import {
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useState
} from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Grow,
  IconButton,
  Dialog as MuiDialog,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { ANIMATION_TIMEOUT_MS, DIALOG_ACTION } from "@utils/config";

interface DialogProps {
  "title"?: string;
  "showCloseBtn"?: boolean;
  "isPersistent"?: boolean;
  "isBackdropBlur"?: boolean;
  "isStopEventPropagation"?: boolean;
  "maxWidth"?: "xs" | "sm" | "md" | "lg" | "xl";
  "height"?: string;
  "initiator": ReactNode;
  "data-testid"?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "children": (handleAgree?: any, handleCancel?: any) => ReactNode;
  "onClose"?: (reason: keyof typeof DIALOG_ACTION) => void;
  "isDefaultOpen"?: boolean;
  "fullWidth"?: boolean;
}

const StyledDialogHeader = styled(Stack)({
  maxWidth: "100%",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "1rem 1.5rem"
});

const StyledDialogTitle = styled(Typography)({
  wordBreak: "break-word"
});

const StyledIconButton = styled(IconButton)({
  marginLeft: "auto"
});

const StyledBlurredBackdrop = styled(Backdrop)({
  backdropFilter: "blur(4px)"
});

// we need to add here "function" keyword the arrow function will throw the error "missing display name"
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Grow ref={ref} {...props} timeout={ANIMATION_TIMEOUT_MS.SLOW} />;
});

const Dialog = ({
  title,
  showCloseBtn = true,
  isPersistent = false,
  maxWidth = "md",
  height = "auto",
  initiator,
  isBackdropBlur = false,
  isStopEventPropagation = false,
  "data-testid": dataTestId,
  children,
  onClose = () => {
    /* no op */
  },
  isDefaultOpen = false,
  fullWidth = true
}: DialogProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(isDefaultOpen);
  }, [isDefaultOpen]);

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    if (isStopEventPropagation) {
      event.stopPropagation();
    }
    setOpen(true);
  };

  const handleClose = (
    event: MouseEvent,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    event.preventDefault();
    if (isStopEventPropagation) {
      event.stopPropagation();
    }
    if (isPersistent && !!reason) {
      return;
    } else {
      onClose(DIALOG_ACTION.CANCEL);
      setOpen(false);
    }
  };

  // Handle Dialog Actions
  const handleAgree = () => {
    onClose(DIALOG_ACTION.AGREE);
    setOpen(false);
  };

  const handleCancel = () => {
    onClose(DIALOG_ACTION.CANCEL);
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleOpen}>{initiator}</Box>
      <MuiDialog
        TransitionComponent={Transition}
        data-testid={dataTestId}
        onClick={(e) => {
          if (isStopEventPropagation) {
            e.stopPropagation();
          }
        }}
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        slots={{ backdrop: isBackdropBlur ? StyledBlurredBackdrop : Backdrop }}
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            height: height
          }
        }}>
        {title || showCloseBtn ? (
          <StyledDialogHeader direction="row">
            {title ? (
              <StyledDialogTitle variant="h5" pt={1}>
                {title}
              </StyledDialogTitle>
            ) : (
              false
            )}

            {showCloseBtn ? (
              <StyledIconButton onClick={handleClose} size="large">
                <CloseIcon />
              </StyledIconButton>
            ) : (
              false
            )}
          </StyledDialogHeader>
        ) : (
          false
        )}

        <Box height="100%">{children(handleAgree, handleCancel)}</Box>
      </MuiDialog>
    </>
  );
};

export default Dialog;
