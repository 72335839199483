import { DialogContent } from "@mui/material";

import Button from "@components/Button";
import CampaignDialog from "@components/CampaignDialog";
import Dialog from "@components/Dialog";

const Campaign = () => {
  return (
    <Dialog
      fullWidth={false}
      showCloseBtn={false}
      initiator={<Button type="submit">Open Campaign Modal</Button>}>
      {(_, handleCancel) => (
        <DialogContent sx={{ padding: 0, maxWidth: "50rem" }}>
          <CampaignDialog handleClose={handleCancel} />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default Campaign;
