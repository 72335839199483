import { Box, Stack, SxProps } from "@mui/material";
import { ref } from "firebase/storage";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import { useUploadFile } from "react-firebase-hooks/storage";

import Avatar from "@components/Avatar";
import Icon from "@components/Icon";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import {
  FILES_LOCATION_PROFILE_PICTURE,
  LOCALE,
  PROFILE_IMAGE_UPLOAD_CONFIG
} from "@utils/config";
import fetchInitials from "@utils/fetchInitials";
import { auth, storage } from "@utils/firebase";
import getFileMetaInformation from "@utils/getFileMetaInformation";
import { getFileURL } from "@utils/getFileURL";
import { getFullLocation } from "@utils/location";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import { intl } from "@utils/translate";

interface ProfileInformationCardProps {
  handleProfilePicChange?: (imageUrl: string) => void;
}

export const PaperSxProps: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    height: "21rem",
    padding: 0
  },
  [theme.breakpoints.up("md")]: {
    height: "16.875rem",
    padding: 0
  },
  [theme.breakpoints.up("lg")]: {
    height: "22.125rem",
    padding: 0
  }
};

const ProfileInformationCard = ({
  handleProfilePicChange
}: ProfileInformationCardProps) => {
  const toast = useToast();
  const [user] = useAuthState(auth);
  const [updateProfile] = useUpdateProfile(auth);
  const { value, setValue } = useUserProfile();
  const [uploadFile] = useUploadFile();

  const profilePicUrl = user?.photoURL ?? "";
  const basicInformation = value?.summary?.basic_information;

  const firstName = resolveMultiLingual(basicInformation?.first_name) ?? "";
  const lastName = resolveMultiLingual(basicInformation?.last_name) ?? "";
  const fullName = `${lastName} ${firstName}`.trim();
  const enInitials =
    fetchInitials(
      `${resolveMultiLingual(
        basicInformation?.first_name,
        LOCALE.EN,
        true
      )} ${resolveMultiLingual(basicInformation?.last_name, LOCALE.EN, true)}`
    ) ?? "";

  const location = getFullLocation(
    basicInformation?.current_location?.city,
    basicInformation?.current_location?.country,
    true
  );

  const updateProfilePicSuccess = () => {
    toast.kampai(intl.get("t_toast_success_profile_changed"), "success");
  };

  const updateProfilePicFail = () => {
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleImageChange = async (image: File) => {
    try {
      const { fileExt, fileType } = getFileMetaInformation(image);

      //TODO: Convert image to PNG and trim size
      if (user?.uid) {
        const path = `${FILES_LOCATION_PROFILE_PICTURE}/${user?.uid}.${fileExt}`;
        const storageRef = ref(storage, path);
        const currentTime = Timestamp.now();

        await uploadFile(storageRef, image, {
          contentType: fileType,
          customMetadata: {
            v: currentTime.toDate().getTime().toString()
          }
        });
        const imageURL = getFileURL(
          FILES_LOCATION_PROFILE_PICTURE,
          user.uid,
          fileExt,
          currentTime
        );

        if (imageURL) {
          await updateProfile({
            photoURL: imageURL
          });
          if (value && setValue) {
            await setValue(
              {
                ...value,
                updated_at: Timestamp.now()
              },
              updateProfilePicSuccess,
              updateProfilePicFail
            );
          }
        }
        if (handleProfilePicChange) {
          handleProfilePicChange(imageURL);
        }

        toast.kampai(intl.get("t_resumes_toast_success_update"), "success");
      }
    } catch (error) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  return (
    <Paper sx={PaperSxProps}>
      <Box id="candidate-profile-info-card">
        <Avatar
          initials={enInitials}
          imgSrc={profilePicUrl ?? ""}
          allowAddPhoto
          config={PROFILE_IMAGE_UPLOAD_CONFIG}
          handleImageChange={handleImageChange}
        />
      </Box>

      <Typography
        variant="h5"
        color={theme.palette.text.primary}
        mt={1.5}
        mb={1}
        textAlign="center">
        {fullName ? fullName : "-"}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        color={theme.palette.text.secondary}
        maxWidth="100%">
        <Typography
          variant="subtitle2"
          ml={1}
          textAlign="center"
          textTransform="capitalize">
          <Icon type="pin_drop" filled /> {location ? location : "-"}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default ProfileInformationCard;
