import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, styled } from "@mui/material";

import Button from "@components/Button";
import Typography from "@components/Typography";

import BG_Campaign_Dialog from "@assets/images/BG_Campaign_Dialog.svg";
import Limited_Offer from "@assets/images/Limited_Offer.svg";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 16,
  right: 16,
  color: theme.palette.text.secondary,
  zIndex: 2,

  [theme.breakpoints.up("sm")]: {
    color: theme.palette.common.white
  }
}));

const StyledBackgroundImage = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "75%",
  objectFit: "cover",
  transform: "rotate(0deg)",
  transformOrigin: "center center",
  bottom: 0,

  [theme.breakpoints.up("sm")]: {
    transform: "none",
    right: 0,
    left: "auto",
    width: "70%",
    height: "100%"
  }
}));

const StyledLimitedOfferImage = styled("img")(({ theme }) => ({
  position: "relative",
  maxWidth: "85%",
  height: "85%",

  [theme.breakpoints.up("sm")]: {
    right: "3rem",
    maxWidth: "95%"
  }
}));

interface CampaignDialogProps {
  handleClose: () => void;
}

const CampaignDialog = ({ handleClose }: CampaignDialogProps) => {
  return (
    <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 1, sm: 3 }}>
      <StyledIconButton onClick={handleClose}>
        <CloseIcon />
      </StyledIconButton>

      <Stack flex={1} justifyContent="center" gap={3} py={3.5} px={2.5}>
        <Typography variant="h4">Title</Typography>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua, 10 resume
          credit.
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          mb={{ xs: 0, sm: 6 }}>
          Promotion period: MM/DD
        </Typography>
        <Box display="inline-flex">
          <Button>Learn More</Button>
        </Box>
      </Stack>

      <Stack
        flex={1}
        position="relative"
        alignItems="center"
        justifyContent="center">
        <StyledBackgroundImage src={BG_Campaign_Dialog} alt="Background" />
        <StyledLimitedOfferImage src={Limited_Offer} alt="Limited Offer" />
      </Stack>
    </Stack>
  );
};

export default CampaignDialog;
