import { useEffect } from "react";

import { useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack } from "@mui/material";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import Button from "@components/Button";
import Radio from "@components/Radio";
import Typography from "@components/Typography";
import WYSIWYG from "@components/WYSIWYG";

import useToast from "@hooks/useToast";

import {
  JOB_DESCRIPTION_FIELD_MAX_LENGTH,
  LOCALE_SHORT,
  WYSIWYG_URL_REGEX
} from "@utils/config";
import { rawDraftContentStateToString } from "@utils/convertDraftContentState";
import { auth, functions } from "@utils/firebase";
import { intl } from "@utils/translate";

interface GptWysiwygTranslatorFormData {
  textToTranslate: RawDraftContentState;
  targetLang: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const GptWysiwygTranslator = () => {
  const toast = useToast();
  const [user] = useAuthState(auth);

  // validation schema
  const schema = yup.object({
    textToTranslate: yup
      .object()
      .shape({
        blocks: yup.array().of(yup.object()).min(1),
        entityMap: yup.object()
      })
      .test(
        "generatedContent",
        intl.get("t_error_required", {
          field: intl.get("t_debugger_gpt_translator")
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length > 0
      )
      .test(
        "generatedContent",
        intl.get("t_error_max_limit", {
          field: intl.get("t_debugger_gpt_translator"),
          maxLimit: JOB_DESCRIPTION_FIELD_MAX_LENGTH
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) =>
          rawDraftContentStateToString(rawDraftState).length <=
          JOB_DESCRIPTION_FIELD_MAX_LENGTH
      )
      .test(
        "generatedContent",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const isLinkNotFound = Object.values(rawDraftState.entityMap).every(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (singleNode: any) => {
              singleNode.type !== "LINK";
            }
          );
          return isLinkNotFound;
        }
      )
      .test(
        "generatedContent",
        intl.get("t_error_urls_are_prohibited"),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (rawDraftState: any) => {
          const text = rawDraftContentStateToString(rawDraftState);
          return !WYSIWYG_URL_REGEX.test(text);
        }
      )
  });

  const [translateText, isTranslateTextLoading, translateTextError] =
    useHttpsCallable(functions, "autoTranslate");

  const methods = useForm({
    defaultValues: {
      textToTranslate: convertToRaw(ContentState.createFromText("")),
      targetLang: LOCALE_SHORT.JA,
      translatedText: convertToRaw(ContentState.createFromText(""))
    },
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, reset, setValue } = methods;

  const langChangeWatcher = useWatch({
    control,
    name: "targetLang"
  });

  const translatedTextWatcher = useWatch({
    control,
    name: "translatedText"
  });

  const handleFormSubmit = async (formData: GptWysiwygTranslatorFormData) => {
    const textToTranslate = draftToHtml(formData.textToTranslate);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await translateText({
      textToTranslate: textToTranslate,
      local: langChangeWatcher
    });
    if (res?.data?.success) {
      const blocksFromHtml = htmlToDraft(res?.data?.translatedText);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const rawContentState = convertToRaw(contentState);
      setValue("translatedText", rawContentState);
    }
  };

  useEffect(() => {
    if (translateTextError) {
      toast.kampai("something went wrong", "error");
    }
  }, [translateTextError]);

  useEffect(() => {
    reset({
      textToTranslate: convertToRaw(ContentState.createFromText("")),
      translatedText: convertToRaw(ContentState.createFromText("")),
      targetLang: langChangeWatcher
    });
  }, [langChangeWatcher]);

  return (
    <>
      {!user ? (
        <Typography>Please login to access this feature</Typography>
      ) : (
        <Box
          noValidate
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}>
          <Radio
            disabled={isTranslateTextLoading}
            control={control}
            name="targetLang"
            label="Select language translation option"
            direction="row"
            required
            options={[
              {
                label: "English to Japanese",
                value: LOCALE_SHORT.JA
              },
              {
                label: "Japanese to English",
                value: LOCALE_SHORT.EN
              }
            ]}
          />
          <WYSIWYG
            defaultValue={convertToRaw(ContentState.createFromText(""))}
            mode="edit"
            control={control}
            name="textToTranslate"
            label="Translate english to japanese"
            placeholder="Enter text"
            required
            disabled={isTranslateTextLoading}
          />
          {rawDraftContentStateToString(translatedTextWatcher) ? (
            <Box>
              <Divider />
              <WYSIWYG
                key={rawDraftContentStateToString(translatedTextWatcher)}
                defaultValue={translatedTextWatcher}
                control={control}
                name="translatedText"
                label="Translated Text"
                required
              />
            </Box>
          ) : (
            false
          )}
          <Stack justifyContent="space-between" direction="row" mt={2}>
            <Button type="submit" loading={isTranslateTextLoading}>
              Translate
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default GptWysiwygTranslator;
